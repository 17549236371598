/* import systemUserAPI from "@/api/systemUser";
import { SystemUser } from "@/models/SystemUser"; */
/* import { useAuthenticationStore } from "@/store/authentication";
import { useProfileStore } from "@/store/profile";
import { useToastStore } from "@/store/toastStore"; */
import userAPI from "@/api/user";
import { useAuthenticationStore } from "@/store/authentication";
import { useProfileStore } from "@/store/profile";
import { useToastStore } from "@/store/toastStore";
import {
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from "vue-router";
import { computed } from "vue";
import logger from "@/utils/logger";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/",
    component: () => import("@/layouts/default.vue"),
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "account",
        name: "Account",
        component: () => import("@/views/Account.vue"),
        /*  children: [
          {
            path: "profile",
            name: "Profile",
            component: () => import("@/views/Account/Profile.vue"),
          },
        ], */
      },
      {
        path: "account",
        children: [
          {
            path: "profile",
            name: "Profile",
            component: () => import("@/views/Account/Profile.vue"),
          },
          {
            path: "listings/:modalType?/:id?",
            name: "Listings",
            component: () => import("@/views/Account/Listings.vue"),
          },
          {
            path: "bookings/:id?",
            name: "Bookings",
            component: () => import("@/views/Account/Bookings.vue"),
          },
          {
            path: "wallet",
            name: "Wallet",
            component: () => import("@/views/Account/Wallet.vue"),
          },
        ],
      },
      // {
      //   path: "account/profile",
      //   name: "Profile",
      //   component: () => import("@/views/Account/Profile.vue"),
      // },
      // {
      //   path: "account/listings",
      //   name: "Listings",
      //   component: () => import("@/views/Account/Listings.vue"),
      // },
      {
        path: "notifications",
        name: "Notifications",
        component: () => import("@/views/Notifications.vue"),
      },
      {
        path: "chat/:id",
        name: "Chat",
        component: () => import("@/views/Notifications/Chat.vue"),
      },
      {
        path: "support",
        name: "Support",
        component: () => import("@/views/Support.vue"),
      },
      {
        path: "explore",
        name: "Explore",
        component: () => import("@/views/Explore.vue"),
      },
      {
        path: "search",
        name: "Search",
        component: () => import("@/views/Search.vue"),
      },
      {
        path: "product/:id",
        name: "Product",
        component: () => import("@/views/Product.vue"),
      },
      {
        path: "admin/systemData",
        name: "Admin_SystemData",
        component: () => import("@/views/Admin/SystemData.vue"),
      },
      {
        path: "admin/users",
        name: "Admin_Users",
        component: () => import("@/views/Admin/Users.vue"),
      },
      {
        path: "admin/sponsored-products",
        name: "Admin_SponsoredProducts",
        component: () => import("@/views/Admin/SponsoredProducts.vue"),
      },
      {
        path: "admin/ads",
        name: "Admin_Ads",
        component: () => import("@/views/Admin/Ads.vue"),
      },
      {
        path: "paymentSuccess",
        name: "PaymentSuccess",
        component: () => import("@/views/Payment/Success.vue"),
      },
      {
        path: "paymentError",
        name: "PaymentError",
        component: () => import("@/views/Payment/Error.vue"),
      },
      {
        path: "paymentCancelled",
        name: "PaymentCancelled",
        component: () => import("@/views/Payment/Cancel.vue"),
      },
      /* {
        path: "forgot-password",
        name: "ResetPassword",
        component: () => import("@/views/Auth/ResetPassword.vue"),
      }, */
      /* {
        path: "privacy-policy",
        name: "PrivacyPolicy",
        component: () => import("@/views/PrivacyPolicy.vue"),
      },
      {
        path: "terms-and-conditions",
        name: "Terms&Conditions",
        component: () => import("@/views/Terms&Conditions.vue"),
      }, */
    ],
  },
];

const authedRoutes = [
  "Profile",
  "Listings",
  "Bookings",
  "Wallet",
  "PaymentSuccess",
  "PaymentError",
  "PaymentCancelled",
  "Notifications",
];
const adminRoutes = [
  "Admin_SponsoredProducts",
  "Admin_Users",
  "Admin_SystemData",
  "Admin_Ads",
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/* async function checkProfile() {
  try {
    const profileStore = useProfileStore();
    if (profileStore.profile == null || profileStore.profile == undefined) {
      const profile = await systemUserAPI.GetSystemUser();
      profileStore.setProfile(profile as SystemUser);
    }
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
} */

router.beforeEach(async (to, from, next) => {
  const profileStore = useProfileStore();
  const authenticationStore = useAuthenticationStore();
  const toastStore = useToastStore();
  const isAdminUser = computed(() => {
    if (profileStore.profile) {
      return profileStore.profile.roleNames.includes("SuperAdmin");
    } else {
      return false;
    }
  });

  if (
    authedRoutes.includes(to.name!.toString()) &&
    authenticationStore.authenticated == false
  ) {
    //toastStore.addToast("warning", "You need to be logged in.");
    authenticationStore.setLoginDialogOpenState(true);
    next(from);
  } else if (
    adminRoutes.includes(to.name!.toString()) &&
    isAdminUser.value == false
  ) {
    toastStore.addToast(
      "warning",
      "You do not have the necessary privileges to access this page. "
    );
    next(from);
  } else {
    if (!profileStore.profile) {
      const userResp = await userAPI.GetCurrentUser();
      if (userResp) {
        profileStore.setProfile(userResp);
        next();
      } else {
        next(from);
      }
    } else {
      next();
    }
  }
});

export default router;
